@import "global";
.case-studies {
  padding-bottom: 10px;
  &__row {
    margin-top:-10px;
  }
  &__column {
    @include flex-grid-column(5, false);
    @include margin(40px null);
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--main {
      @include flex-grid-column(12, false);
    }
    &--medium {
      @include flex-grid-column(7, false);
      .study-card__inner {
        @include visual-angle('top right',120px, 120px, $cWhite, false);
      }
    }
    &--right {
      text-align: right;
      position: relative;
      z-index: 3;
      .study-card__inner {
        @include visual-angle('bottom right',120px, 120px, $cWhite, false);
        .study-card__tags {
          padding-bottom: 50px;
        }
      }
      &--second {
        .study-card__inner {
          @include visual-angle('bottom left',120px, 120px, $cWhite, false);
          .study-card__tags {
            padding-bottom: 50px;
          }
          &:before {
            transform: translate(-50%, 50%) rotate(-45deg);
            left: 0;
          }
        }
      }
    }
  }
  &__all-works {
    @include flex-grid-column(10,false);
  }
  @include breakpoint(tablet-landscape down) {
    &__column {
      &--medium {
        @include flex-grid-column(6, false);
      }
      &--right {
        @include flex-grid-column(6, false);
      }
    }
  }
  @include breakpoint(tablet-portrait down) {
    &__column {
      &--medium {
        @include flex-grid-column(12, false);
        .study-card__inner {
          &:before {
            @include size(80px);
          }
        }
      }
      &--right {
        @include flex-grid-column(12, false);
        .study-card__inner {
          &:before {
            @include size(80px);
          }
        }
        &--second {
          .study-card__inner {
            &:before {
              @include size(80px);
            }
          }
        }
      }
    }
  }
}
